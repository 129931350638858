<template>
  <v-row :no-gutters="$vuetify.breakpoint.xsOnly" align="center" class="my-2">
    <v-col class="text-start" cols="12" md="4" sm="6">
      <span class="text-body-1">
        {{ $vuetify.lang.t('$vuetify.pages.site.form.fields.finalMessage1') }}
        <br>
        <br>
        {{ $vuetify.lang.t('$vuetify.pages.site.form.fields.finalMessage2') }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import Site from '@/modules/app/models/site'

export default {
  name: 'KurccSiteStepperStep9Content',
  props: {
    site: {
      type: Site,
      required: true
    }
  },
  computed: {
    siteData: {
      get () {
        return this.site
      },
      set (v) {
        this.$emit('update:site', v)
      }
    }
  }
}
</script>
